'use strict';

var fp = require('lodash/fp');

var AddressKinds = /* @__PURE__ */ ((AddressKinds2) => {
  AddressKinds2["Housing"] = "Housing";
  AddressKinds2["Owner"] = "Owner";
  return AddressKinds2;
})(AddressKinds || {});
function formatAddress(address, additionalAddress) {
  const label = address.label.replace(/(\d{5})/, ", $1").replace(/(2A|2B)(\d{3})/, ", $1$2").split(",").map(fp.trim);
  return fp.compact([additionalAddress, ...label]);
}

const CADASTRAL_CLASSIFICATION_VALUES = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8
];

var Occupancy = /* @__PURE__ */ ((Occupancy2) => {
  Occupancy2["UNKNOWN"] = "inconnu";
  Occupancy2["VACANT"] = "V";
  Occupancy2["RENT"] = "L";
  Occupancy2["SHORT_RENT"] = "B";
  Occupancy2["PRIMARY_RESIDENCE"] = "P";
  Occupancy2["SECONDARY_RESIDENCE"] = "RS";
  Occupancy2["COMMERCIAL_OR_OFFICE"] = "T";
  Occupancy2["DEPENDENCY"] = "N";
  Occupancy2["DEMOLISHED_OR_DIVIDED"] = "D";
  Occupancy2["OTHERS"] = "A";
  return Occupancy2;
})(Occupancy || {});
const OCCUPANCY_VALUES = Object.values(Occupancy);

const ENERGY_CONSUMPTION_VALUES = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G"
];

const ESTABLISHMENT_KIND_VALUES = [
  "ASSO",
  "CA",
  "CC",
  "Commune",
  "CTU",
  "CU",
  "DEP",
  "ME",
  "PETR",
  "REG",
  "SDED",
  "SDER",
  "Service d\xE9concentr\xE9 de l'\xC9tat \xE0 comp\xE9tence (inter) d\xE9partementale",
  "SIVOM"
];

const ESTABLISHMENT_SOURCE_VALUES = [
  "seed",
  "manual",
  "cerema"
];

var HousingKind = /* @__PURE__ */ ((HousingKind2) => {
  HousingKind2["APARTMENT"] = "APPART";
  HousingKind2["HOUSE"] = "MAISON";
  return HousingKind2;
})(HousingKind || {});
const HOUSING_KIND_VALUES = Object.values(HousingKind);

var HousingStatus = /* @__PURE__ */ ((HousingStatus2) => {
  HousingStatus2[HousingStatus2["NEVER_CONTACTED"] = 0] = "NEVER_CONTACTED";
  HousingStatus2[HousingStatus2["WAITING"] = 1] = "WAITING";
  HousingStatus2[HousingStatus2["FIRST_CONTACT"] = 2] = "FIRST_CONTACT";
  HousingStatus2[HousingStatus2["IN_PROGRESS"] = 3] = "IN_PROGRESS";
  HousingStatus2[HousingStatus2["COMPLETED"] = 4] = "COMPLETED";
  HousingStatus2[HousingStatus2["BLOCKED"] = 5] = "BLOCKED";
  return HousingStatus2;
})(HousingStatus || {});
const HOUSING_STATUS_VALUES = Object.values(
  HousingStatus
).filter((status) => typeof status !== "string");
function isHousingStatus(value) {
  return HOUSING_STATUS_VALUES.includes(value);
}

const OWNER_KIND_VALUES = [
  "particulier",
  "sci-copro",
  "promoteur",
  "etat-collectivite",
  "bailleur-social",
  "autres",
  "absent"
];
const OWNER_KIND_LABELS = {
  particulier: "Particulier",
  "sci-copro": "SCI, Copropri\xE9t\xE9, Autres personnes morales",
  promoteur: "Promoteur, Investisseur priv\xE9",
  "etat-collectivite": "Etat et collectivit\xE9 territoriale",
  "bailleur-social": "Bailleur social, Am\xE9nageur, Investisseur public",
  autres: "Autres",
  absent: "Absence de propri\xE9taire"
};

var RolesDTO = /* @__PURE__ */ ((RolesDTO2) => {
  RolesDTO2[RolesDTO2["Usual"] = 0] = "Usual";
  RolesDTO2[RolesDTO2["Admin"] = 1] = "Admin";
  return RolesDTO2;
})(RolesDTO || {});

exports.AddressKinds = AddressKinds;
exports.CADASTRAL_CLASSIFICATION_VALUES = CADASTRAL_CLASSIFICATION_VALUES;
exports.ENERGY_CONSUMPTION_VALUES = ENERGY_CONSUMPTION_VALUES;
exports.ESTABLISHMENT_KIND_VALUES = ESTABLISHMENT_KIND_VALUES;
exports.ESTABLISHMENT_SOURCE_VALUES = ESTABLISHMENT_SOURCE_VALUES;
exports.HOUSING_KIND_VALUES = HOUSING_KIND_VALUES;
exports.HOUSING_STATUS_VALUES = HOUSING_STATUS_VALUES;
exports.HousingKind = HousingKind;
exports.HousingStatus = HousingStatus;
exports.OCCUPANCY_VALUES = OCCUPANCY_VALUES;
exports.OWNER_KIND_LABELS = OWNER_KIND_LABELS;
exports.OWNER_KIND_VALUES = OWNER_KIND_VALUES;
exports.Occupancy = Occupancy;
exports.RolesDTO = RolesDTO;
exports.formatAddress = formatAddress;
exports.isHousingStatus = isHousingStatus;
