'use strict';

var RolesDTO = require('./RolesDTO-6Fh-jauz.js');
var utils = require('@zerologementvacant/utils');
var fp = require('lodash/fp');

const BENEFIARY_COUNT_VALUES = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "gte5"
];

const BUILDING_PERIOD_VALUES = [
  "lt1919",
  "1919to1945",
  "1946to1990",
  "gte1991"
];

const CAMPAIGN_COUNT_VALUES = ["0", "1", "2", "gt2"];

const CAMPAIGN_STATUS_VALUES = [
  "draft",
  "sending",
  "in-progress",
  "archived"
];
const CAMPAIGN_STATUS_LABELS = {
  draft: "Envoi en attente",
  sending: "En cours d\u2019envoi",
  "in-progress": "Envoy\xE9e",
  archived: "Archiv\xE9e"
};
function nextStatus(current) {
  if (current === "archived") {
    return null;
  }
  return CAMPAIGN_STATUS_VALUES[CAMPAIGN_STATUS_VALUES.indexOf(current) + 1];
}
function isCampaignStatus(value) {
  return typeof value === "string" && CAMPAIGN_STATUS_VALUES.includes(value);
}
const byStatus = utils.contramap(
  (status) => CAMPAIGN_STATUS_VALUES.indexOf(status)
)(utils.DEFAULT_ORDER);
const byCreatedAt = utils.contramap(
  (campaign) => campaign.createdAt
)(utils.DEFAULT_ORDER);

const RESOURCE_VALUES = [
  "6-utilisateurs-de-zlv-sur-votre-structure",
  "7-autres-structures-de-votre-territoires-inscrites-sur-zlv",
  "13-analyses",
  "15-analyses-activites"
];

const DATA_FILE_YEAR_VALUES = [
  "ff-2023-locatif",
  "lovac-2019",
  "lovac-2020",
  "lovac-2021",
  "lovac-2022",
  "lovac-2023",
  "lovac-2024"
];

function toAddress(housing) {
  const streetNumber = fp.trimCharsStart("0", housing.dnvoiri);
  const repetition = housing.dindic ?? "";
  const street = housing.dvoilib;
  const zipcode = housing.idcom;
  const city = housing.idcomtxt;
  return `${streetNumber}${repetition} ${street}, ${zipcode} ${city}`;
}
function toOccupancy(ccthp) {
  const occupancy = RolesDTO.OCCUPANCY_VALUES.find((occupancy2) => occupancy2 === ccthp);
  return occupancy ?? RolesDTO.Occupancy.UNKNOWN;
}

function isChild(geoCodes) {
  return (establishment) => {
    return establishment.geoCodes.some((geoCode) => geoCodes.has(geoCode));
  };
}
function isDepartmentalEstablishment(establishment) {
  const departments = [
    "DEP",
    "SDED",
    "SDER",
    "SIVOM",
    "REG",
    "CTU"
  ];
  return departments.includes(establishment.kind);
}
function isIntercommunalityEstablishment(establishment) {
  const intercommunalities = [
    "CA",
    "CC",
    "CU",
    "ME"
  ];
  return intercommunalities.includes(establishment.kind);
}

const EVENT_CATEGORY_VALUES = [
  "Ownership",
  "Followup",
  "Diagnostic",
  "Campaign",
  "Group",
  "Trade"
];

const EVENT_KIND_VALUES = ["Create", "Update", "Delete"];

const EVENT_SECTION_VALUES = [
  "Situation",
  "Suivi de campagne",
  "Propri\xE9taire",
  "Coordonn\xE9es propri\xE9taire",
  "Ajout d\u2019un logement dans un groupe",
  "Retrait du logement d\u2019un groupe",
  "Archivage d\u2019un groupe",
  "Suppression d\u2019un groupe"
];

const HOUSING_BY_BUILDING_VALUES = [
  "lt5",
  "5to19",
  "20to49",
  "gte50"
];

const HOUSING_SOURCE_VALUES = [
  "lovac",
  "datafoncier-manual",
  "datafoncier-import"
];

function isSecondaryOwner(housingOwner) {
  return housingOwner.rank >= 2;
}

const LIVING_AREA_VALUES = [
  "lt35",
  "35to74",
  "75to99",
  "gte100"
];

const LOCALITY_KIND_VALUES = ["ACV", "PVD"];

const OWNER_AGE_VALUES = [
  "lt40",
  "40to59",
  "60to74",
  "75to99",
  "gte100"
];

function getAddress(owner) {
  if (owner.banAddress) {
    return RolesDTO.formatAddress(owner.banAddress, owner.additionalAddress);
  }
  return !owner.additionalAddress ? owner.rawAddress : [owner.additionalAddress, ...owner.rawAddress];
}

const OWNERSHIP_KIND_VALUES = ["single", "co", "other"];
const INTERNAL_MONO_CONDOMINIUM_VALUES = ["single"];
const INTERNAL_CO_CONDOMINIUM_VALUES = [
  "co",
  "CL",
  "CLV",
  "CV"
];

const PRECISION_CATEGORY_VALUES = [
  "dispositifs-incitatifs",
  "dispositifs-coercitifs",
  "hors-dispositif-public",
  "blocage-involontaire",
  "blocage-volontaire",
  "immeuble-environnement",
  "tiers-en-cause",
  "travaux",
  "occupation",
  "mutation"
];
const PRECISION_MECHANISM_CATEGORY_VALUES = [
  "dispositifs-incitatifs",
  "dispositifs-coercitifs",
  "hors-dispositif-public"
];
const PRECISION_BLOCKING_POINT_CATEGORY_VALUES = [
  "blocage-involontaire",
  "blocage-volontaire",
  "immeuble-environnement",
  "tiers-en-cause"
];
const PRECISION_EVOLUTION_CATEGORY_VALUES = ["travaux", "occupation", "mutation"];
function isPrecisionMechanismCategory(category) {
  return PRECISION_MECHANISM_CATEGORY_VALUES.includes(category);
}
function isPrecisionBlockingPointCategory(category) {
  return PRECISION_BLOCKING_POINT_CATEGORY_VALUES.includes(category);
}
function isPrecisionEvolutionCategory(category) {
  return PRECISION_EVOLUTION_CATEGORY_VALUES.includes(category);
}

const ROOM_COUNT_VALUES = ["1", "2", "3", "4", "gte5"];

function isEmpty(signatory) {
  return fp.every(fp.isNull, signatory);
}

const VACANCY_RATE_VALUES = [
  "lt20",
  "20to39",
  "40to59",
  "60to79",
  "gte80"
];

const VACANCY_YEAR_VALUES = [
  "2021",
  "2020",
  "2019",
  "2018to2015",
  "2014to2010",
  "before2010",
  "missingData",
  "inconsistency2022"
];

const VARIABLES_OPTIONS = [
  "{{owner.fullName}}",
  "{{housing.rawAddress}}",
  "{{housing.localId}}",
  "{{housing.invariant}}",
  "{{housing.cadastralReference}}",
  "{{housing.housingKind}}",
  "{{housing.livingArea}}",
  "{{housing.roomsCount}}",
  "{{housing.buildingYear}}",
  "{{housing.vacancyStartYear}}",
  "{{housing.energyConsumption}}"
];
function isVariableOption(value) {
  return VARIABLES_OPTIONS.find((option) => option === value) !== void 0;
}
function replaceVariables(str, replacement) {
  return str.replaceAll("{{owner.fullName}}", replacement.owner.fullName ?? "").replaceAll(
    "{{housing.rawAddress}}",
    replacement.housing.rawAddress?.join(", ") ?? ""
  ).replaceAll("{{housing.localId}}", replacement.housing.localId ?? "").replaceAll("{{housing.invariant}}", replacement.housing.invariant ?? "").replaceAll(
    "{{housing.cadastralReference}}",
    replacement.housing.cadastralReference ?? ""
  ).replaceAll(
    "{{housing.housingKind}}",
    replacement.housing.housingKind ?? ""
  ).replaceAll(
    "{{housing.livingArea}}",
    replacement.housing.livingArea?.toString()?.concat("m\xB2") ?? ""
  ).replaceAll(
    "{{housing.roomsCount}}",
    replacement.housing.roomsCount?.toString() ?? ""
  ).replaceAll(
    "{{housing.buildingYear}}",
    replacement.housing.buildingYear?.toString() ?? ""
  ).replaceAll(
    "{{housing.vacancyStartYear}}",
    replacement.housing.vacancyStartYear?.toString() ?? ""
  ).replaceAll(
    "{{housing.energyConsumption}}",
    replacement.housing.energyConsumption ?? ""
  );
}

exports.AddressKinds = RolesDTO.AddressKinds;
exports.CADASTRAL_CLASSIFICATION_VALUES = RolesDTO.CADASTRAL_CLASSIFICATION_VALUES;
exports.ENERGY_CONSUMPTION_VALUES = RolesDTO.ENERGY_CONSUMPTION_VALUES;
exports.ESTABLISHMENT_KIND_VALUES = RolesDTO.ESTABLISHMENT_KIND_VALUES;
exports.ESTABLISHMENT_SOURCE_VALUES = RolesDTO.ESTABLISHMENT_SOURCE_VALUES;
exports.HOUSING_KIND_VALUES = RolesDTO.HOUSING_KIND_VALUES;
exports.HOUSING_STATUS_VALUES = RolesDTO.HOUSING_STATUS_VALUES;
exports.HousingKind = RolesDTO.HousingKind;
exports.HousingStatus = RolesDTO.HousingStatus;
exports.OCCUPANCY_VALUES = RolesDTO.OCCUPANCY_VALUES;
exports.OWNER_KIND_LABELS = RolesDTO.OWNER_KIND_LABELS;
exports.OWNER_KIND_VALUES = RolesDTO.OWNER_KIND_VALUES;
exports.Occupancy = RolesDTO.Occupancy;
exports.RolesDTO = RolesDTO.RolesDTO;
exports.formatAddress = RolesDTO.formatAddress;
exports.isHousingStatus = RolesDTO.isHousingStatus;
exports.BENEFIARY_COUNT_VALUES = BENEFIARY_COUNT_VALUES;
exports.BUILDING_PERIOD_VALUES = BUILDING_PERIOD_VALUES;
exports.CAMPAIGN_COUNT_VALUES = CAMPAIGN_COUNT_VALUES;
exports.CAMPAIGN_STATUS_LABELS = CAMPAIGN_STATUS_LABELS;
exports.CAMPAIGN_STATUS_VALUES = CAMPAIGN_STATUS_VALUES;
exports.DATA_FILE_YEAR_VALUES = DATA_FILE_YEAR_VALUES;
exports.EVENT_CATEGORY_VALUES = EVENT_CATEGORY_VALUES;
exports.EVENT_KIND_VALUES = EVENT_KIND_VALUES;
exports.EVENT_SECTION_VALUES = EVENT_SECTION_VALUES;
exports.HOUSING_BY_BUILDING_VALUES = HOUSING_BY_BUILDING_VALUES;
exports.HOUSING_SOURCE_VALUES = HOUSING_SOURCE_VALUES;
exports.INTERNAL_CO_CONDOMINIUM_VALUES = INTERNAL_CO_CONDOMINIUM_VALUES;
exports.INTERNAL_MONO_CONDOMINIUM_VALUES = INTERNAL_MONO_CONDOMINIUM_VALUES;
exports.LIVING_AREA_VALUES = LIVING_AREA_VALUES;
exports.LOCALITY_KIND_VALUES = LOCALITY_KIND_VALUES;
exports.OWNERSHIP_KIND_VALUES = OWNERSHIP_KIND_VALUES;
exports.OWNER_AGE_VALUES = OWNER_AGE_VALUES;
exports.PRECISION_BLOCKING_POINT_CATEGORY_VALUES = PRECISION_BLOCKING_POINT_CATEGORY_VALUES;
exports.PRECISION_CATEGORY_VALUES = PRECISION_CATEGORY_VALUES;
exports.PRECISION_EVOLUTION_CATEGORY_VALUES = PRECISION_EVOLUTION_CATEGORY_VALUES;
exports.PRECISION_MECHANISM_CATEGORY_VALUES = PRECISION_MECHANISM_CATEGORY_VALUES;
exports.RESOURCE_VALUES = RESOURCE_VALUES;
exports.ROOM_COUNT_VALUES = ROOM_COUNT_VALUES;
exports.VACANCY_RATE_VALUES = VACANCY_RATE_VALUES;
exports.VACANCY_YEAR_VALUES = VACANCY_YEAR_VALUES;
exports.byCreatedAt = byCreatedAt;
exports.byStatus = byStatus;
exports.getAddress = getAddress;
exports.isCampaignStatus = isCampaignStatus;
exports.isChild = isChild;
exports.isDepartmentalEstablishment = isDepartmentalEstablishment;
exports.isEmpty = isEmpty;
exports.isIntercommunalityEstablishment = isIntercommunalityEstablishment;
exports.isPrecisionBlockingPointCategory = isPrecisionBlockingPointCategory;
exports.isPrecisionEvolutionCategory = isPrecisionEvolutionCategory;
exports.isPrecisionMechanismCategory = isPrecisionMechanismCategory;
exports.isSecondaryOwner = isSecondaryOwner;
exports.isVariableOption = isVariableOption;
exports.nextStatus = nextStatus;
exports.replaceVariables = replaceVariables;
exports.toAddress = toAddress;
exports.toOccupancy = toOccupancy;
