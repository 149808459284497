'use strict';

var fp = require('lodash/fp');
var pino = require('pino');
var clientS3 = require('@aws-sdk/client-s3');

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

var Comparison = /* @__PURE__ */ ((Comparison2) => {
  Comparison2[Comparison2["A_GT_B"] = 1] = "A_GT_B";
  Comparison2[Comparison2["A_EQ_B"] = 0] = "A_EQ_B";
  Comparison2[Comparison2["B_GT_A"] = -1] = "B_GT_A";
  return Comparison2;
})(Comparison || {});
const DEFAULT_ORDER = (first, second) => first < second ? -1 /* B_GT_A */ : first > second ? 1 /* A_GT_B */ : 0 /* A_EQ_B */;
function desc(ord = DEFAULT_ORDER) {
  return (first, second) => ord(second, first);
}
function min(ord = DEFAULT_ORDER) {
  return (first, second) => ord(first, second) === -1 /* B_GT_A */ ? second : first;
}
function max(ord = DEFAULT_ORDER) {
  return (first, second) => ord(first, second) > 0 ? first : second;
}
function contramap(f) {
  return (ord) => {
    return (first, second) => ord(f(first), f(second));
  };
}
const isNull = (a) => a === null;
const isUndefined = (a) => a === void 0;
function not(f) {
  return (a) => !f(a);
}
const isNotNull = (a) => not(isNull)(a);
const isDefined = (a) => not(isUndefined)(a);

function createQuery(params) {
  if (fp.isEmpty(params)) {
    return "";
  }
  return fp.pipe(
    // Faster than fp.omitBy
    fp.pickBy((value) => {
      return !fp.isNil(value) && (fp.isBoolean(value) || fp.isNumber(value) || !fp.isEmpty(value));
    }),
    (params2) => new URLSearchParams(params2),
    (params2) => params2.toString().length > 0 ? `?${params2}` : ""
  )(params);
}

var LogLevel = /* @__PURE__ */ ((LogLevel2) => {
  LogLevel2["SILLY"] = "silly";
  LogLevel2["TRACE"] = "trace";
  LogLevel2["DEBUG"] = "debug";
  LogLevel2["INFO"] = "info";
  LogLevel2["WARN"] = "warn";
  LogLevel2["ERROR"] = "error";
  LogLevel2["FATAL"] = "fatal";
  return LogLevel2;
})(LogLevel || {});
const LOG_LEVELS = Object.values(LogLevel);

let baseLogger;
function createLogger(name, opts) {
  const level = opts.level ?? LogLevel.DEBUG;
  const developmentOptions = {
    transport: {
      target: "pino-pretty"
    }
  };
  baseLogger = baseLogger ?? pino({
    ...opts.isProduction ? {} : developmentOptions,
    level: LogLevel.DEBUG
  });
  const logger = baseLogger.child({ name }, { level });
  return {
    trace: toPinoLogFn(logger.trace.bind(logger)),
    debug: toPinoLogFn(logger.debug.bind(logger)),
    info: toPinoLogFn(logger.info.bind(logger)),
    warn: toPinoLogFn(logger.warn.bind(logger)),
    error: toPinoLogFn(logger.error.bind(logger))
  };
}
function toPinoLogFn(log) {
  return (messageOrData, data) => {
    if (typeof messageOrData === "string") {
      log(data, messageOrData);
    } else {
      log(messageOrData);
    }
  };
}

function merge(strategies) {
  return (first2, second2) => {
    const keys = fp.union(Object.keys(first2), Object.keys(second2));
    return keys.reduce((acc, key) => {
      const strategy = strategies[key];
      if (strategy) {
        return {
          ...acc,
          [key]: strategy(first2[key], second2[key])
        };
      }
      return acc;
    }, {});
  };
}
const first = (first2, _) => first2;
const second = (_, second2) => second2;
const firstDefined = (first2, second2) => first2 ?? second2;
const byLength = fp.pipe(
  DEFAULT_ORDER,
  contramap((a) => a.length)
);
const oldest = min();
const youngest = max();
const shortest = min(byLength);
const longest = max(byLength);

function keys(a) {
  return Object.keys(a);
}
function compact(a) {
  return fp.pipe(fp.pickBy((value) => !fp.isNil(value)))(a);
}
function compactUndefined(a) {
  return fp.pipe(fp.pickBy((value) => !fp.isUndefined(value)))(a);
}

function createS3(opts) {
  return new clientS3.S3Client({
    endpoint: opts.endpoint,
    region: opts.region,
    forcePathStyle: true,
    credentials: {
      accessKeyId: opts.accessKeyId,
      secretAccessKey: opts.secretAccessKey
    }
  });
}
async function getBase64Content(logo, opts) {
  const { response, content } = await getContent(logo, opts);
  return toBase64(content, response.ContentType);
}
function toBase64(content, type) {
  return `data:${type};charset=utf-8;base64, ${content}`;
}
async function getContent(logo, opts) {
  const command = new clientS3.GetObjectCommand({
    Bucket: opts.bucket,
    Key: logo
  });
  const response = await opts.s3.send(command);
  if (!response.Body) {
    throw new Error(`File ${logo} not found`);
  }
  const content = await response.Body?.transformToString("base64");
  return { response, content };
}

function timestamp(date = /* @__PURE__ */ new Date()) {
  return date.toJSON().substring(0, "yyyy-mm-ddThh:mm:ss".length).replace(/[-T:]/g, "");
}
function slugify(text) {
  return text.toString().toLowerCase().trim().replace(/\s+/g, "-").replace(/&/g, "-and-").replace(/[^\w-]+/g, "").replace(/--+/g, "-");
}

exports.Comparison = Comparison;
exports.DEFAULT_ORDER = DEFAULT_ORDER;
exports.LOG_LEVELS = LOG_LEVELS;
exports.LogLevel = LogLevel;
exports.byLength = byLength;
exports.compact = compact;
exports.compactUndefined = compactUndefined;
exports.contramap = contramap;
exports.createLogger = createLogger;
exports.createQuery = createQuery;
exports.createS3 = createS3;
exports.desc = desc;
exports.first = first;
exports.firstDefined = firstDefined;
exports.getBase64Content = getBase64Content;
exports.getContent = getContent;
exports.isDefined = isDefined;
exports.isNotNull = isNotNull;
exports.isNull = isNull;
exports.isUndefined = isUndefined;
exports.keys = keys;
exports.longest = longest;
exports.max = max;
exports.merge = merge;
exports.min = min;
exports.not = not;
exports.oldest = oldest;
exports.second = second;
exports.shortest = shortest;
exports.slugify = slugify;
exports.timestamp = timestamp;
exports.toBase64 = toBase64;
exports.wait = wait;
exports.youngest = youngest;
